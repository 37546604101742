import { Controller } from "@hotwired/stimulus";
import FloatLabels from 'float-labels.js'

export default class extends Controller {

  static targets = ['form'];

  static values = {
    clear: String,
  }

  connect() {
    this.floatLabels = new FloatLabels(this.formTarget, {
      style: 2,
      customPlaceholder: function( placeholderText, el, labelEl ) {
        return `${placeholderText}${$(labelEl).hasClass('required') ? '*' : ''}`;
      },
      customLabel: function( labelEl, el ) {
        return `${labelEl.textContent}${$(labelEl).hasClass('required') ? '*' : ''}`;
      },
    });
  }

  get form() {
    return $(this.formTarget);
  }

  setTracker() {
    if (window.pi) {
      let input = this.form.find('input[id*="tracker"]');
      if (input) {
        input.val(JSON.stringify(window.pi.tracker));
        console.log(input.val(), input)
      }
    }
  }

  rebuildLabels() {
    setTimeout(() => {
      this.floatLabels.rebuild();
    }, 0)
  }

  submitForm(event) {
    let isValid = this.validateForm();

    // If our form is invalid, prevent default on the event
    // so that the form is not submitted
    if (!isValid) {
      event.preventDefault();
      return;
    }
    if (this.clearValue === "true") {
      this.clearFormValues()
    }
    if (event.type !== 'ajax:beforeSend') {
      Rails.fire(this.formTarget, 'submit');
    }
  }

  clearFormValues() {
    for (let obj in sessionStorage) {
      if (sessionStorage.hasOwnProperty(obj) && obj.includes("form-values")) {
        sessionStorage.removeItem(obj);
      }
    }
  }

  validateForm() {
    let isValid = true;

    let requiredFieldSelectors = 'trix-editor[required], input:required, select[required], textarea[required], input[type="checkbox"]';
    let requiredFields = $(this.formTarget).find(requiredFieldSelectors).not('.optional');
    let inputField = null;
    requiredFields.each(function() {
      let field = $(this);
      if (field.is(':checkbox')) {
        if (!field.prop('checked')) {
          field.addClass('is-invalid')
        } else {
          field.removeClass('is-invalid')
        }
      }
      if (!field.prop('disabled') && field.is(":visible") && (!field.val().toString().trim() && !field.data('dnd'))) {
        if (inputField === null) {
          inputField = $(this);
        }
        field.addClass('is-invalid');
        field.prev().addClass('is-invalid');
        isValid = false;
      }
    });
    if (!isValid) {
      inputField.focus();
    }
    // If we already know we're invalid, just return false
    if (!isValid) {
      return false;
    }

    // Search for any browser invalidated input fields and focus them
    let invalidFields = $(this.formTarget).find('input:invalid').not('.optional');
    invalidFields.each(function() {
      let field = $(this);
      if (!field.prop('disabled') && field.is(':visible') && !field.data('dnd')) {
        if (inputField === null) {
          inputField = field;
        }
        field.addClass('is-invalid');
        field.prev().addClass('is-invalid');
        isValid = false;
      }
    });
    if (!isValid) {
      inputField.focus();
    }


    return isValid;
  }

  disconnect() {
    this.floatLabels.destroy();
  }

}