import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['content'];

  connect() {
    $(this.contentTarget).hide();
  }

  toggle() {
    $(this.contentTarget).toggle();
  }
}