import { Controller } from "@hotwired/stimulus";
import jsxElem, { render } from "jsx-no-react";

export default class extends Controller {

  static targets = ['header', 'body', 'footer'];

  static values = {
    open: String,
    id: String,
    classes: String,
    footerClasses: String,
    title: String,
    header: String,
  }

  connect() {
    if (this.openValue == 'true') {
      this.open();
    }
  }

  get modal() {
    return $(`#${this.idValue}`);
  }

  get header() {
    if (this.hasHeaderTarget) {
      return $(this.headerTarget);
    }
  }

  get body() {
    if (this.hasBodyTarget) {
      return $(this.bodyTarget);
    }
  }

  get footer() {
    if (this.hasFooterTarget) {
      return $(this.footerTarget);
    }
  }

  open() {
    let self = this;
    if (this.modal.length === 0) {
      render(<div className="modal fade" id={this.idValue} tabIndex="-1">
        <div className={`modal-dialog modal-dialog-centered ${this.classesValue}`}>
          <div className="modal-content">
            {this.headerValue === 'true' && (
              <div className="modal-header">
                {this.titleValue && (
                  <span>
                      <h5 className="modal-title">{this.titleValue || 'No Title'}</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </span>
                )}

              </div>
            )}
            <div className="modal-body">

            </div>
            {this.hasFooterTarget && (
              <div className={`modal-footer ${this.footerClassesValue}`}>

              </div>
            )}
          </div>
        </div>
      </div>, document.getElementById('app-container'));
      this.insertContentFor('header');
      this.insertContentFor('body');
      this.insertContentFor('footer');
      this.modal.modal('show');
      this.modal.on('hidden.bs.modal', function (e) {
        self.close();
      })
    }
    setTimeout(() => {
      const event = new Event("modal-opened");
      window.dispatchEvent(event);
    }, 0);
  }

  insertContentFor(type) {

    if (this[type] && this[type].children().length) {
      this.modal.find(`.modal-${type}`).append(this[type].html());
    } else if (this.data.get(`${type}-url`)) {
      let elem = $('<div data-controller="remote-content">');
      elem.attr('data-remote-content-url', this.data.get(`${type}-url`));
      this.modal.find(`.modal-${type}`).append(elem);
    }
  }

  close() {
    this.modal.remove();
    const event = new CustomEvent("modal-closed");
    window.dispatchEvent(event);
  }

  disconnect() {
    this.close();
  }


}