import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "container" ]

  get container() {
    return this.containerTarget
  }

  connect() {
    let container = $(this.container);
    this.totalItems = container.data("count") || 0;
    this.currentItemsCount = 10;
    this.hasAdditionalContent = this.totalItems > 10;
    this.loading = false;
    if (this.hasAdditionalContent) {
      this.nextPage = 2
    }
    $(window).on('scroll', () => {
      if ($(window).scrollTop() >= container.offset().top + container.outerHeight() - window.innerHeight && this.hasAdditionalContent && !this.loading) {
        this.loadNextPage()
      }
    });
  }

  loadNextPage() {
    this.loading = true;

    $.ajax({
      url: window.location.href,
      data: {page: this.nextPage},
      dataType: "json",
      error: () => {
        console.log("Something went wrong, we won't load more")
      },
      success: (result) => {
        $(this.container).append(result.content);
        this.currentItemsCount += result.itemsAdded;
        if (this.currentItemsCount < this.totalItems) {
          this.loading = false;
          this.nextPage += 1;
        }
      }
    })
  }

  disconnect() {
    $(window).unbind('scroll');
  }
}