import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        target: String
    }

    scrollToTarget() {
        let scrollTarget = this.targetValue;
        let element = document.getElementById(scrollTarget);
        if (element) {
            element.scrollIntoView();
        }
    }
}
