import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['message', 'closeButton'];

  connect() {
    this.waitForIntercom();
  }

  waitForIntercom() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.id === "intercom-frame") {
            this.intercomLoad(node);
          }
        });
      });
    });
    this.observer.observe(document.body, { childList: true, subtree: true });
  }

  intercomLoad(iframe) {
    if (iframe.contentWindow.document.readyState === 'complete') {
      this.iframeLoaded();
    } else {
      iframe.addEventListener('load', this.iframeLoaded.bind(this));
    }
    this.observer.disconnect();
  }

  iframeLoaded() {
    if (sessionStorage.getItem('messageDismissed') === 'true') {
      this.reset();
    } else {
      this.showMessageBox();
    }
  }

  showMessageBox() {
    this.messageTarget.className = "show";
  }

  hideMessage() {
    this.messageTarget.className = "";
    sessionStorage.setItem('messageDismissed', 'true');
    this.reset();
  }

  reset() {
    setTimeout(() => {
      sessionStorage.removeItem('messageDismissed');
      this.showMessageBox();
    }, 600000);
  }
}