import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    window.onload = function() {
      buttonAction('.lead-magnet', 'click->lead-magnet#open click->subscribe#close click->request-proposal#close click->schedule-demo#close click->send-message#close click->register#close');
      buttonAction('.schedule-demo', 'click->lead-magnet#close click->subscribe#close click->request-proposal#close click->schedule-demo#open click->send-message#close click->register#close');
      buttonAction('.blog-subscribe', 'click->lead-magnet#close click->subscribe#open click->request-proposal#close click->schedule-demo#close click->send-message#close click->register#close');
      buttonAction('.register', 'click->register#open click->lead-magnet#close click->subscribe#close click->request-proposal#close click->schedule-demo#close click->send-message#close');
    }
    function buttonAction(className, dataAction) {
      let elements = document.querySelectorAll(className);
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute("data-action", dataAction);
      }
    }
  }
}