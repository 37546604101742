import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.registerAliasedControllers()
  }

  registerAliasedControllers() {
    for (let [alias, original] of this.controllerIdentifiersByAlias) {
      if (!this.moduleForIdentifier(alias)) {
        this.application.register(alias, this.constructorForIdentifier(original))
      }
    }
  }

  constructorForIdentifier(identifier) {
    return this.moduleForIdentifier(identifier).definition.controllerConstructor;
  }

  moduleForIdentifier(identifier) {
    return this.application.router.modulesByIdentifier.get(identifier)
  }

  get aliasDescriptors() {
    return this.element.dataset.alias.split(/\s/);
  }

  get controllerIdentifiersByAlias() {
    return new Map(this.aliasDescriptors.map(descriptor => descriptor.split(/->/)))
  }
}