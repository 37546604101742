import { Controller } from "@hotwired/stimulus";
import FloatLabels from "float-labels.js";

export default class extends Controller {

  static targets = ['source', 'copyButton'];

  static values = {
    fullLink: String,
  }

  connect() {
    let maxLength = 30;
    let fullLink = this.fullLinkValue;
    let displayInput = this.sourceTarget;

    if (fullLink.length > maxLength) {
      displayInput.value = fullLink.substring(0, maxLength) + '...';
    } else {
      displayInput.value = fullLink;
    }

    this.floatLabels = new FloatLabels(this.element, {
      style: 2,
      customPlaceholder: function( placeholderText, el, labelEl ) {
        return `${placeholderText}${$(labelEl).hasClass('required') ? '*' : ''}`;
      },
      customLabel: function( labelEl, el ) {
        return `${labelEl.textContent}${$(labelEl).hasClass('required') ? '*' : ''}`;
      },
    });
  }

  copy() {
    this.sourceTarget.select();
    navigator.clipboard.writeText(this.fullLinkValue).then(() => {
      this.copyButtonTarget.textContent = 'COPIED!';

      setTimeout(() => {
        this.copyButtonTarget.textContent = 'COPY';
      }, 2000);
    })
  }


}