import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['container'];

  get container() {
    return $(this.containerTarget);
  }

  connect() {
    let width = window.innerWidth;
    let maxNumOfVideos = (width / 200);
    this.container.children().each((index, element) => {
      if ((index + 2) > maxNumOfVideos) {
        $(element).addClass('d-none')
      } else {
        $(element).removeClass('d-none')
      }
    })
  }

  pause() {
    this.endlessScroll.slick('slickPause')
  }
  resume() {
    this.endlessScroll.slick('slickPlay')
  }

  disconnect() {
    // this.endlessScroll.slick('unslick');
  }
}