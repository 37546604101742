// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import mixpanel from "mixpanel-browser";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require('packs/social-share-button.js.coffee')
require('typeface-montserrat')

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'
import 'controllers'
import 'bootstrap-select'
import 'slick-carousel'
import ActiveStorageDragAndDrop from 'active_storage_drag_and_drop';
ActiveStorageDragAndDrop.start()

mixpanel.init($('meta[name="mixpanel-app-token"]').attr('content'), {
  debug: false,
});
mixpanel.track("pageview");

function setHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  setHeight();

  let url = location.href.replace(/\/$/, "");

  if (location.hash && $('#sub-sections').length > 0) {
    const hash = url.split("#");
    $('#sub-sections a[href="#'+hash[1]+'"]').tab("show");
    url = location.href.replace(/\/#/, "#");
    history.replaceState(null, null, url);
  }

  $('a[data-toggle="tab"]').on("click", function() {
    let newUrl;
    const hash = $(this).attr("href");
    newUrl = url.split("#")[0] + hash;
    history.replaceState(null, null, newUrl);
    $('html, body').animate({
      scrollTop: $(this).offset().top
    }, 'slow');
  });
})

$(window).on('resize', setHeight);

require("trix")
require("@rails/actiontext")