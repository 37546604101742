import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    id: String,
    title: String,
  }

  autoselect() {
    let title = this.titleValue;
    document.getElementById("dropdown").value = this.idValue;
    let searchString = 'Which webinar(s) would you like to register for?';
    let dropdownTitle = document.getElementsByClassName('filter-option-inner-inner')
    for (let i = 0; i < dropdownTitle.length; i++) {
      if (dropdownTitle[i].innerHTML.indexOf(searchString) !== -1) {
        dropdownTitle[0].innerHTML = title;
      }
    }

  }

}