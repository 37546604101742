import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "clearField"]

  static values = {
    errorMessage: String,
  }

  connect() {
    this.element.addEventListener("ajax:error", this.displayError.bind(this))
  }

  displayError(event) {
    const errorMessage = this.errorMessageValue;
    const errorDiv = document.createElement('div');
    errorDiv.classList.add('alert', 'alert-danger', 'text-center', 'mt-3');
    errorDiv.innerHTML = errorMessage;
    this.formTarget.prepend(errorDiv);
    this.clearFieldTarget.value = ""

    setTimeout(() => {
      errorDiv.classList.add('fade-out')
      errorDiv.addEventListener('transitionend', () => errorDiv.remove())
    }, 2000)
  }
}
