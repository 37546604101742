import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "field" ]

  get inputField() {
    return this.targets.find('field')
  }

  onFilterChange(event) {
  }

  connect() {
    let renderItem = function(item, escape) {
      return `<div data-type="${item.type}">${item.name}</div>`;
    };
    let label = $(this.inputField).siblings('.floating-label');
    let options = $(this.inputField).data("options");
    // initialize the field
    $(this.inputField).selectize({
      valueField: 'id',
      searchField: ['name'],
      sortField: "name",
      labelField: "name",
      maxItems: $(this.inputField).attr('multiple') ? options.length : options.length,
      create: false,
      render: {
        option: renderItem,
        item: renderItem
      },
      options
    });
    let selectizeControl = $(this.inputField).siblings('.selectize-control');
    selectizeControl.append(label)
  }
  disconnect() {
    this.inputField.selectize.destroy()
  }
}