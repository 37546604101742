import { Controller } from "@hotwired/stimulus";
import mixpanel from "mixpanel-browser";

export default class extends Controller {

  static values = {
    email: String,
    firstName: String,
    lastName: String,
    id: String,
  }

  connect() {
    let mixpanelEmail = this.emailValue;
    let mixpanelFirstName = this.firstNameValue;
    let mixpanelLastName = this.lastNameValue;
    let mixpanelId = this.idValue;

    mixpanel.identify(mixpanelEmail)
    mixpanel.people.set({
      "$first_name": mixpanelFirstName,
      "$last_name": mixpanelLastName,
      "$email": mixpanelEmail
    })
  }
}