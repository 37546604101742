import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

  static targets = ['toast'];

  connect() {
    let flash = $(this.toastTarget);
    if (flash) {
      flash.toast('show');
    }
  }

}