// app/javascript/controllers/notification_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["banner"];
    static values = { id: Number };

    connect() {
        this.showBanner();
    }

    hideBanner() {
        this.bannerTarget.style.display = "none";
        document.cookie = `dismissed_${this.idValue}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

    showBanner() {
        this.bannerTarget.style.display = "block";
    }
}
