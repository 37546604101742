import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ['field'];

    // initialize with the current date
    connect() {
        this.fieldTarget.value = new Date().toISOString().split('T')[0];
    }

    // to be used with focus events so the field changes to a date field
    date() {
        this.fieldTarget.type = "date";
    }

    // use with blur event to change back to text after selecting the date
    text() {
        this.fieldTarget.type = "text";
    }

    showCalendar() {
        this.fieldTarget.showPicker();
    }

}