import { Controller } from "@hotwired/stimulus";


export default class extends Controller {


  static targets = ['label'];

  updateLabel(e) {
    let action = $(e.currentTarget);
    if (action.data('label')) {
      $(this.labelTarget).text(action.data('label'));
    }
  }

}