import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    onInit: String,
    anchor: String
  }

  connect() {
    if (this.onInitValue == 'true') {
      this.anchor();
    }
  }

  anchor(e) {
    $('html,body').animate({scrollTop: $(`#${this.anchorValue}`).offset().top},'slow');
  }

}