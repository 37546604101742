import { Controller } from "@hotwired/stimulus";
import moment from 'moment';

export default class extends Controller {

  static values = {
    time: String,
    type: String,
    zone: String,
  }

  connect() {
    let zone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]
    let time = this.timeValue;
    let type = this.typeValue;
    let showZone = this.zoneValue;
    if (type) {
      switch (type) {
        case 'time':
          if(showZone) {
            $(this.element).text(`${moment(time).format("h:mm A")} ${zone}`);
          }
          else {
            $(this.element).text(moment(time).format("h:mm A"));
          }
          break;
        case 'spelled-date':
          $(this.element).text(moment(time).format("MMMM D, YYYY"));
          break;
      }
    } else {
      $(this.element).text(moment(time).calendar());
    }
  }

}