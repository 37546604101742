import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["field"]

  initialize() {
    $(this.fieldTarget).selectpicker('refresh');
  }

  disconnect() {
    $(this.element).find('select').selectpicker('destroy');
  }
}