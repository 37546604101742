import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['input', 'label', 'container'];

  connect() {
    this.initValue();
  }

  get input() {
    return $(this.inputTarget);
  }

  get label() {
    if (this.hasLabelTarget) {
      return $(this.labelTarget);
    }
  }

  get container() {
    if (this.hasContainerTarget) {
      return $(this.containerTarget);
    }
  }

  get inputName() {
    return this.input.attr('name');
  }

  initValue() {
    let storedValue = sessionStorage.getItem(`form-values-${this.inputName}`);
    if(this.input.val().length === 0 && `${storedValue}`.length > 0) {
      this.input.val(storedValue);
      const event = new CustomEvent("input-changed")
      window.dispatchEvent(event)
    }
  }

  init(e) {
    let action = $(e.currentTarget);
    if (action.data(`input-${this.identifier}-enabled`) == false) {
      this.container.hide()
    } else {
      if (this.container) {
        this.container.show();
      }
    }
    if (action.data(`input-${this.identifier}-label`)) {
      this.setLabel(action.data(`input-${this.identifier}-label`))
    }

    if (action.data(`input-${this.identifier}-value`)) {
      this.setValue(action.data(`input-${this.identifier}-value`))
    }
  }

  setValue(val) {
    this.input.val(val);
  }

  setLabel(label) {
    this.label.text(label);
  }


  storeValue() {
    sessionStorage.setItem(`form-values-${this.inputName}`, this.input.val())
  }
  checkValue() {
    if (this.input.val().length > 0) {
      this.input.removeClass('is-invalid');
      this.input.prev().removeClass('is-invalid');
    }
  }
}