import { Controller } from "@hotwired/stimulus";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

export default class extends Controller {

  static targets = ['mapContainer', 'overlay', 'controller', 'caret'];

  connect() {
    const reader = require('g-sheets-api');
    const readerOptions = {
      sheetId: '1wNYb0tYue8msZGu9Q_ixrmxeCUChrTe55qBhxYLPUDo',
      apiKey: 'AIzaSyApQWEyMBFfQcNawpuuzfog233PwtDjUJo',
      returnAllResults: true,
      filter: {
        'key to filter on': 'value to match',
      },
    };


    reader(readerOptions, (results) => {
      mapboxgl.accessToken = 'pk.eyJ1Ijoia2FkaW5jZSIsImEiOiJja2N1dTkxOWkwMG5kMnJwYjBwNzZiNmdhIn0.f8fl27ayV_8wrkH8n_nufg';

      let geocodes = results;

      let geojson = [];
      this.map = new mapboxgl.Map({
        container: this.mapContainerTarget,
        style: 'mapbox://styles/kadince/ckdhnrhdq00jh1ilot9jnardy',
        attributionControl: false,
        center: [-93.5804, 40.53483],
        zoom: 3.25,
        minZoom: 3.25,
        maxZoom: 3.25,
      });
      geocodes.forEach((geocode) => {
        geojson.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [geocode.longitude, geocode.latitude],
          }
        })
      });
      this.map.scrollZoom.disable();
      this.map.dragPan.disable();
      this.map.on('load', () => {
        let layers = this.map.getStyle().layers;
        let firstSymbolId;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol') {
            firstSymbolId = layers[i].id;
            break;
          }
        }
        this.map.addSource('branches', {
          'type': 'geojson',
          'data': {"type":"FeatureCollection","features": geojson }
        });
        this.map.addLayer(
          {
            'id': 'branches',
            "type": "symbol",
            'source': 'branches',
            layout: {
              'icon-image':  'marker',
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom'
            }
          },
          firstSymbolId
        );
        this.resize();
      });
    });
  }

  resize() {
    $(this.mapContainerTarget).css('height', `${this.overlayTarget.offsetHeight + 200}px`);
    this.map.resize();
  }

  viewMap() {
    $(this.overlayTarget).addClass('hide');
    $(this.caretTarget).removeClass('invisible');
    this.map.dragPan.enable();
  }
  hideMap() {
    $(this.overlayTarget).removeClass('hide');
    $(this.caretTarget).addClass('invisible');
    this.map.dragPan.disable();
  }

  disconnect() {
    this.map.remove();
  }
}