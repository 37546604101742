import { Controller } from "@hotwired/stimulus";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

export default class extends Controller {
  connect() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoia2FkaW5jZSIsImEiOiJja2N1dTkxOWkwMG5kMnJwYjBwNzZiNmdhIn0.f8fl27ayV_8wrkH8n_nufg';

    this.map = new mapboxgl.Map({
      container: this.element,
      style: 'mapbox://styles/kadince/ckevklsb5ajkl19ml4x0vdg82',
      attributionControl: false,
      center: [-111.968740, 41.306550],
      zoom: 12,
      minZoom: 12,
      maxZoom: 12,
    });
    this.map.scrollZoom.disable();
    this.map.dragPan.disable();
    let el = document.createElement('div');
    el.className = 'marker';
    let popup = new mapboxgl.Popup({ anchor: 'bottom', offset: 30, closeButton: false }).setHTML('<div class="text-center"><h3 class="mb-3">Kadince Address</h3><p class="mb-0">2637 North Washington Boulevard #154</p><p>North Ogden, UT 84414</p></div>');
    let marker = new mapboxgl.Marker({element: el, anchor: 'bottom-left'})
      .setLngLat([-111.968740, 41.306550])
      .setPopup(popup)
      .addTo(this.map);
  }
}