import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['field', 'dependent']
  static values = {
    requiredId: String,
    selectId: String,
    desiredValue: String,
    url: String,
    parameter: String,
    fieldName: String,
    fieldTitle: String,
    jsonResponse: String
  }

  add() {
    let desiredValue = this.desiredValueValue;
    let selectList = this.selectIdValue;
    let requiredField = this.requiredIdValue;

    if (document.getElementById(selectList).value === desiredValue) {
      document.getElementById("hidden-field").style.display = "block";
      document.getElementById("hidden-field").classList.remove("d-none");
      if (requiredField) {
        document.getElementById(requiredField).required = true;
      }
    } else {
      document.getElementById("hidden-field").style.display = "none";
    }
  }

  connect() {
    this.checkSelectedOption();
  }

  checkSelectedOption() {
    if (this.fieldTargets.length > 0) {
      const selectedValue = this.fieldTarget.value;

      if (selectedValue && selectedValue.trim() !== '') {
        let url = this.urlValue;
        let parameter = this.parameterValue;
        let value = $(this.fieldTarget).val();
        let dependentField = this.dependentTarget;
        let fieldName = this.fieldNameValue;
        let fieldTitle = this.fieldTitleValue;
        let jsonResponse = this.jsonResponseValue;
        let data = {};
        data[parameter] = value;

        $.ajax({
          url: url,
          method: "GET",
          dataType: "json",
          data: data,
          error: function (xhr, status, error) {
            console.error('AJAX Error: ' + status + error);
          },
          success: function (response) {
            let items = response[jsonResponse];

            $(dependentField).html('<input name="' + fieldName + '" type="hidden" value=""><div class="dropdown bootstrap-select show-tick form-control dropdown-text"><select multiple="multiple" class="form-control dropdown-text" id="multi-field" data-title="' + fieldTitle + '" data-style="btn-select" required="required" name="' + fieldName + '" tabindex="null"></select><div class="dropdown-menu "><div class="inner show" role="listbox" id="bs-select-2" tabindex="-1" aria-multiselectable="true"><ul class="dropdown-menu inner show" role="presentation"></ul></div></div></div>');
            for(let i = 0; i < items.length; i++){
              $("#multi-field").append('<option value="' + items[i]["id"] + '">' + items[i]["name"] + '</option>');
            }
            $("#multi-field").selectpicker();
            if(items.length === 0) {
              $("#multi-field").selectpicker('destroy');
              $("#multi-field").hide();
            }
          }
        });
      }
    }
  }
}