import { Controller } from "@hotwired/stimulus";
import videojs from 'video.js';
import 'videojs-overlay';
import 'videojs-contrib-quality-levels';
import DeviceDetector from "device-detector-js";
import mixpanel from 'mixpanel-browser';

require('packs/videojs-event-tracking.js');

export default class extends Controller {

  static targets = ['player', 'title', 'playingOverlay', 'endOverlay'];

  static values = {
    replay: String,
    videoTitle: String,
  }

  get title() {
    if (this.hasTitleTarget) {
      return $(this.titleTarget);
    }
  }

  get playingOverlay() {
    if (this.hasPlayingOverlayTarget) {
      return $(this.playingOverlayTarget);
    }
  }

  get endOverlay() {
    if (this.hasEndOverlayTarget) {
      return $(this.endOverlayTarget);
    }
  }

  goFullScreen() {
    this.player.muted(false);
    this.player.requestFullscreen();
    this.player.trigger('goFullScreen');
    this.player.controls(true)
  }

  play() {
    this.player.play()
  }

  connect() {
    const startTime = new Date();
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);
    const maskImage = $(this.playerTarget).data('mask-image');
    if (maskImage) {
      document.documentElement.style.setProperty('--videoMaskImageUrl', String.raw`url(${maskImage})`);
    }
    this.player = videojs(this.playerTarget, {
      plugins: {
        eventTracking: true,
      },
      controls: true,
      autoplay: $(this.playerTarget).data('autoplay'),
      preload: 'auto',
      fluid: true,
      poster: $(this.playerTarget).data('poster'),
      controlBar: {
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
        currentTimeDisplay: true,
        timeDivider: true,
        durationDisplay: true,
        children: [
          "progressControl",
          "playToggle",
          'currentTimeDisplay', 'timeDivider', 'durationDisplay',
          "volumePanel",
          "captionsButton",
          "fullscreenToggle"
        ]
      }
    });

    this.player.on('timeupdate', () => {
      const currentTime = new Date();
      const targetTime = this.player.duration() * 0.75;
      if (((currentTime - startTime) / 1000) > targetTime) {
        if (document.getElementById("hidden-button")) {
          document.getElementById("hidden-button").classList.remove("d-none");
        }
      }
    });

    let overlays = [];
    let title = $(this.playerTarget).data('title');
    if (!title && this.title) {
      title = this.title.html();
    }
    if (title) {
      overlays.push({
        start: 'loadstart',
        content: title,
        end: 'playing',
        align: 'top-left',
        class: 'secondary'
      });
    }
    if (this.playingOverlay && device.device.type === 'desktop') {
      overlays.push({
        start: 'videoStarted',
        content: this.playingOverlay.html(),
        end: 'goFullScreen',
        align: 'center',
        class: 'secondary'
      });
    }
    let button = $(this.playerTarget).data('button');
    if (button) {
      overlays.push({
        content: button,
        start: 'playing',
        end: 'paused',
        align: 'bottom',
      });
    }
    this.player.overlay({
      overlays
    })

    if (this.replayValue) {
      this.player.trigger('videoStarted');
      this.player.on('fullscreenchange', () => {
        if (this.player.isFullscreen()) {
          this.player.trigger('goFullScreen');
        }
      })
      this.player.on('volumechange', () => {
        if (!this.player.muted()) {
          this.player.trigger('goFullScreen');
        }
      })
      this.player.on('ended', () => {
        this.player.exitFullscreen();
        this.player.muted(true);
        this.player.play();
        this.player.trigger('videoStarted');
      })
    }
    let qualityLevels = this.player.qualityLevels();
    qualityLevels.on('addqualitylevel', function(event) {
      let qualityLevel = event.qualityLevel;

      if (qualityLevel.height >= 720) {
        qualityLevel.enabled = true;
      } else {
        qualityLevel.enabled = false;
      }
    });
    let toggleQuality = (function() {
      let enable720 = true;

      return function() {
        for (let i = 0; i < qualityLevels.length; i++) {
          let qualityLevel = qualityLevels[i];
          if (qualityLevel.height >= 1080) {
            qualityLevel.enabled = enable720;
          } else {
            qualityLevel.enabled = !enable720;
          }
        }
        enable720 = !enable720;
      };
    })();toggleQuality();
    this.player.on('loadedmetadata', () => {
      $('#duration').text(Math.round(this.player.duration()));
    })
    let videoTitleElement = this.videoTitleValue;
    let presenterElement = document.getElementById('webinar-presenter');
    if (videoTitleElement) {
      var videoTitle = videoTitleElement
    }
    if (presenterElement) {
      var webinarPresenter = presenterElement.innerHTML
    }
    this.trackEvents(videoTitle, webinarPresenter)
  }

  trackEvents(videoTitle, webinarPresenter) {
    let overview_video = document.getElementById("overview-video");
    if(!overview_video) {
      this.player.on('tracking:firstplay', (e, data) => mixpanel.track(`${videoTitle} - video started`, {
        "Data": data,
        "Video": videoTitle,
        "Presenter(s)": webinarPresenter
      }))
      this.player.on('tracking:first-quarter', (e, data) => mixpanel.track(`${videoTitle} - watched first quarter`, {
        "Data": data,
        "Video": videoTitle,
        "Presenter(s)": webinarPresenter
      }))
      this.player.on('tracking:second-quarter', (e, data) => mixpanel.track(`${videoTitle} - watched half`, {
        "Data": data,
        "Video": videoTitle,
        "Presenter(s)": webinarPresenter
      }))
      this.player.on('tracking:third-quarter', (e, data) => mixpanel.track(`${videoTitle} - watched three-quarters`, {
        "Data": data,
        "Video": videoTitle,
        "Presenter(s)": webinarPresenter
      }))
      this.player.on('tracking:fourth-quarter', (e, data) => mixpanel.track(`${videoTitle} - video finished`, {
        "Data": data,
        "Video": videoTitle,
        "Presenter(s)": webinarPresenter
      }))
    }
  }
}