import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['drawer', 'caret', 'image', 'title'];

  static values = {
    partialOpenInit: String,
    bottomScroll: String,
    hash: String,
    disableInit: String,

  }

  connect() {
    if (this.partialOpenInitValue) {
      this.setOpenDrawer(true);
    }
    if (this.bottomScrollValue) {
      this.openAtBottom();
    }
    let openDrawer = sessionStorage.getItem('open-drawer');
    if (this.hashValue && this.hashValue === window.location.hash) {
      setTimeout(() => {
        this.open()
      }, 350)
    } else if (openDrawer === this.identifier && this.disableInitValue !== 'true') {
      this.drawer.addClass('with-data');
      this.body.css("margin-bottom", 100);
      $('.with-tabs').first().addClass('drawer-bottom');
    }
  }

  heightListener(event) {
    let drawer = this;
    const height = $(document).height() - document.documentElement.clientHeight
    const atBottom = (window.scrollY / height) === 1
    if (atBottom) {
      drawer.open()
    }
  }

  get caret() {
    return $(this.caretTarget);
  }

  get body() {
    return $('body');
  }

  get drawer() {
    return $(this.drawerTarget);
  }

  get image() {
    return $(this.imageTarget);
  }

  get title() {
    return $(this.titleTarget);
  }

  open(e) {
    let image = e ? $(e.currentTarget).data('image') : null;
    let title = e ? $(e.currentTarget).data('title') : null;
    this.drawer.addClass('open with-data');
    this.caret.addClass('open');
    this.body.css("margin-bottom", `${this.drawer.height()}px`)
    this.body.addClass('drawer-open');
    $('.with-tabs').first().addClass('drawer-bottom');
    if (image) {
      if (this.currentImage) {
        this.image.removeClass(this.currentImage);
      }
      this.image.addClass(image);
      this.currentImage = image;
    }
    if (title) {
      this.title.text(title);
    }
    this.setOpenDrawer(true);
    const event = new CustomEvent("drawer-open")
    window.dispatchEvent(event)
  }

  openAtBottom() {
    this.boundHeightListener = this.heightListener.bind(this)
    document.addEventListener("scroll", this.boundHeightListener);
  }

  toggle() {
    if (this.drawer.hasClass('open')) {
      this.drawer.removeClass('open');
      this.caret.removeClass('open');
      this.body.removeClass('drawer-open');
      this.body.css("margin-bottom", 100);
    } else {
      this.open();
    }
  }

  setOpenDrawer(open) {
    if (open) {
      sessionStorage.setItem('open-drawer', this.identifier);
    } else {
      sessionStorage.removeItem('open-drawer');
    }
  }

  close() {
    this.drawer.removeClass('open');
    this.drawer.removeClass('with-data');
    this.caret.removeClass('open');
    this.body.removeClass('drawer-open');
    this.body.css("margin-bottom", 0)
    $('.with-tabs').first().removeClass('drawer-bottom');
    this.setOpenDrawer();
  }

  openIfClosed(event) {
    if (!this.drawer.hasClass('open') && event.target !== this.caretTarget) {
      this.open();
    } else if (document.getElementById('close-button')) {
      let drawers = document.getElementsByClassName('bottom-drawer');
      for(let i = 0; i < drawers.length; i++) {
        drawers[i].removeAttribute('data-action');
      }
      this.close()
    }
  }

  refresh() {
    window.location.reload();
  }

  disconnect() {
    document.removeEventListener("scroll", this.boundHeightListener);
  }

}