import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['loader'];

  connect() {
    $(this.element).html($('<div class="spinner-border" role="status" data-remote-content-target="loader">'))
    $.get(this.data.get('url')).then((data) => {
      $(this.loaderTarget).replaceWith(data.content);
    })
  }
}